import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

// var baseUrl = 'http://localhost:5000/';
// var baseUrl = 'https://api.ecacmande.co.za/';

export default new Vuex.Store({
  state: {
    graphOptions: []
  },
  mutations: {
    setUrlConfig: (state, payload) => {
      localStorage.setItem('apiUrl', payload.api_url)
      // localStorage.setItem('apiUrl', baseUrl)
      localStorage.setItem('urlDate', payload.information_date)
    },
  },
  actions: {
    getChartsInformation({state}){
      return new Promise((resolve, reject) =>{
        axios.get(localStorage.getItem('apiUrl') + 'Data/getsumaries/' + localStorage.getItem('urlDate'))
        .then(response => {
          state.graphOptions = response.data
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
  modules: {
  }
})
