<template>
  <div class="home">
    <b-row class="pt-2">
      <b-col cols="6" sm="4" md="2" lg="2" v-for="(item, index) in graphOptions" :key="index" fluid>
        <b-card class="info-card">
          <p>{{item.description}}</p>
          <h4>{{item.count}}</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col  md="4" sm="12" v-for="(item, index) in graphOptions" :key="index" class="mb-4 graphs">
        <b-card>
          <b-row class="mt-0" align-h="between">
            <b-col>
              <h5>{{item.description}}</h5>
            </b-col>
            <b-col class="text-right" cols="3">
              <h5>{{item.count}}</h5>
            </b-col>
          </b-row>
          <b-skeleton-img v-if="isLoading"></b-skeleton-img>
          <bar-chart v-if="!isLoading" :data="item.chartData" :options="chartOptions" :height="200"></bar-chart>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center" class="mb-4">
      <b-link href="https://www.ecacmande.co.za/">Home</b-link>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
  import BarChart from "@/components/BarChart.js";

  export default {
    name: 'Home',
    components: {
      BarChart
    },
    data: () => ({
      graphOptions: [{
          description: 'HIV Tests Done: 15 years and older (excluding ANC)',
          count: 0,
          chartData: {}
        },
        {
          description: 'Positive HIV Tests: 15 years and older (excluding ANC)',
          count: 0,
          chartData: {}
        },
        {
          description: 'Infant PCR Positive Test Around 10 weeks',
          count: 0,
          chartData: {}
        },
        {
          description: 'Delivery 10 - 14 years in facility',
          count: 0,
          chartData: {}
        },
        {
          description: 'Delivery 15-19 years in facility',
          count: 0,
          chartData: {}
        },
        {
          description: 'ART client naïve start ART during month',
          count: 0,
          chartData: {}
        }
      ],
      isLoading: true,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        }
      }
    }),
    computed:{
      graphHeightCalc() {
        if (screen.width<=600) {
          return screen.height * 0.2
        }

        return 120
      },
    },

    mounted(){
      this.$store.commit('setUrlConfig', this.$root.urlConfig)
    },
    created() {
      this.getChartsInformation().then((response) => {
        let newOptions = []
        response.forEach(item => {
          let counter = 0
          item.data.forEach(count => {
            counter += count
          });

          newOptions.push({
            description: item.decription,
            count: counter,
            chartData: {
              hoverBackgroundColor: "red",
              hoverBorderWidth: 10,
              labels: [
                'April', 'May', 'June', 'July', 'August', 'September',
                'October', 'November', 'December','January', 'February', 'March'
              ],
              datasets: [{
                label: 'Total Tests',
                backgroundColor: '#A10707',
                pointBackgroundColor: 'white',
                borderWidth: 1,
                pointBorderColor: '#A10707',
                data: item.data
              }]
            }
          })
        })
        this.graphOptions = newOptions

        this.isLoading = false
      })
    },
    methods: {
      ...mapActions(['getChartsInformation']),
    }
  }
</script>
<style>

</style>