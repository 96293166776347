import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './style.scss'


Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

fetch('/config.json')
  .then(res => res.json())  
  .then(config => {
    new Vue({
      data: () => ({
        urlConfig : config
      }),
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })